import Swiper from "swiper/bundle";
import jump from "jump.js";

class Swipers {
  constructor() {
    if (document.querySelector(".c-swiper1")) {
      this.swiper1();
    }
    if (document.querySelector(".c-swiper2")) {
      this.swiper2();
    }
    if (document.querySelector(".c-swiper-fullscreen1")) {
      this.swiperFullscreen1();
    }
  }

  swiper1() {
    const swipers = document.querySelectorAll(".c-swiper1");

    swipers.forEach((el) => {
      const swiper = new Swiper(el.querySelector(".swiper"), {
        speed: 500,
        spaceBetween: 24,
        slidesPerView: 1,
        navigation: {
          nextEl: el.querySelector(".swiper-button-next"),
          prevEl: el.querySelector(".swiper-button-prev"),
        },
      });
    });
  }

  swiper2() {
    const swipers = document.querySelectorAll(".c-swiper2");

    swipers.forEach((el) => {
      const swiper = new Swiper(el.querySelector(".swiper"), {
        speed: 750,
        spaceBetween: 0,
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: el.querySelector(".swiper-button-next"),
          prevEl: el.querySelector(".swiper-button-prev"),
        },
        pagination: {
          el: el.querySelector(".swiper-pagination"),
          clickable: true,
        },
      });
    });
  }

  swiperFullscreen1() {
    const swiper = new Swiper(".c-swiper-fullscreen1", {
      direction: "vertical",
      loop: false,
      speed: 1200,
      effect: "fade",
      virtualTranslate: true,
      //preventInteractionOnTransition: true,
      //followFinger: false,
      autoHeight: true,
      mousewheel: {
        invert: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        bulletActiveClass: "-current",
        renderBullet: (index, className) => {
          const totalNum = 4;
          let num = ("00" + (index + 1)).slice(-2);
          if (index == totalNum) {
            num = "Top";
          }
          return '<button class="p-careertop-block1__slide1nav__item1 ' + className + '">' + num + "</button>";
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
      },
      on: {
        init: function () {
          document.body.classList.add("-fullscreen");
          document.documentElement.classList.add("-fullscreen");
        },
        reachEnd: function () {
          setTimeout(function () {
            document.body.classList.remove("-fullscreen");
            document.documentElement.classList.remove("-fullscreen");
            swiper.allowTouchMove = false;
            swiper.mousewheel.disable();
            swiper.params.mousewheel.releaseOnEdges = true;
            swiper.params.touchReleaseOnEdges = true;
          }, 200);
        },
        fromEdge: function () {
          setTimeout(function () {
            if (!document.body.classList.contains("-fullscreen")) {
              document.body.classList.add("-fullscreen");
              document.documentElement.classList.add("-fullscreen");
              swiper.allowTouchMove = true;
              swiper.mousewheel.enable();
              swiper.params.mousewheel.releaseOnEdges = false;
              swiper.params.touchReleaseOnEdges = false;
            }
          }, 200);
        },
        slideChange: function () {
          let pos = window.scrollY;
          if (pos > 0) {
            setTimeout(function () {
              window.scrollTo(0, 0);
            }, 200);
          }
        },
      },
    });
    window.addEventListener("load", () => {
      jump(0);
    });
    window.addEventListener("scroll", () => {
      if (!document.body.classList.contains("-fullscreen")) {
        let pos = window.scrollY;
        if (pos == 0) {
          swiper.allowTouchMove = true;
          swiper.mousewheel.enable();
        }
      }
    });
  }
}

export default Swipers;
