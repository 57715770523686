import { list } from "postcss";
import { isMobileSize } from "../common/isMobileSize";

class LocalNav1 {
  constructor() {

    const sustainability1 = document.querySelector(".include_localNav_sustainability1");
    if( sustainability1 ) {
      fetch("/_includes/localNav_sustainability1.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_sustainability1");
        if (target) {
          target.innerHTML = data;
          this.hoverLocalNav();
          this.scrollLocalNav();
          this.setCurrentClass();
        }
      });
    }

    const sustainability1_footer = document.querySelector(".include_localNav_sustainability1_footer");
    if( sustainability1_footer ) {
      fetch("/_includes/localNav_sustainability1_footer.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_sustainability1_footer");
        if (target) {
          target.innerHTML = data;
        }
      });
    }

    const ir1 = document.querySelector(".include_localNav_ir1");
    if( ir1 ) {
      fetch("/_includes/localNav_ir1.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_ir1");
        if (target) {
          target.innerHTML = data;
          this.hoverLocalNav();
          this.scrollLocalNav();
          this.setCurrentClass();
        }
      });
    }

    const ir1_footer = document.querySelector(".include_localNav_ir1_footer");
    if( ir1_footer ) {
      fetch("/_includes/localNav_ir1_footer.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_ir1_footer");
        if (target) {
          target.innerHTML = data;
        }
      });
    }

    const career1 = document.querySelector(".include_localNav_career1");
    if( career1 ) {
      fetch("/_includes/localNav_career1.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_career1");
        if (target) {
          target.innerHTML = data;
          this.hoverLocalNav();
          this.scrollLocalNav();
          this.setCurrentClass();
          this.scrollLocalNavSticky();
        }
      });
    }

    const career1_footer = document.querySelector(".include_localNav_career1_footer");
    if( career1_footer ) {
    fetch("/_includes/localNav_career1_footer.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_career1_footer");
        if (target) {
          target.innerHTML = data;
        }
      });
    }

    const about1 = document.querySelector(".include_localNav_about1");
    if( about1 ) {
      fetch("/_includes/localNav_about1.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_about1");
        if (target) {
          target.innerHTML = data;
          this.hoverLocalNav();
          this.scrollLocalNav();
          this.setCurrentClass();
        }
      });
    }

    const about1_footer = document.querySelector(".include_localNav_about1_footer");
    if( about1_footer ) {
      fetch("/_includes/localNav_about1_footer.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_about1_footer");
        if (target) {
          target.innerHTML = data;
        }
      });
    }

    const learn1 = document.querySelector(".include_localNav_learn1");
    if( learn1 ) {
      fetch("/_includes/localNav_learn1.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_learn1");
        if (target) {
          target.innerHTML = data;
          this.hoverLocalNav();
          this.scrollLocalNav();
          this.setCurrentClass();
        }
      });
    }

    const learn1_footer = document.querySelector(".include_localNav_learn1_footer");
    if( learn1_footer ) {
      fetch("/_includes/localNav_learn1_footer.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_learn1_footer");
        if (target) {
          target.innerHTML = data;
        }
      });
    }

    const business1 = document.querySelector(".include_localNav_business1");
    if( business1 ) {
      fetch("/_includes/localNav_business1.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_business1");
        if (target) {
          target.innerHTML = data;
          this.hoverLocalNav();
          this.scrollLocalNav();
          this.setCurrentClass();
        }
      });
    }

    const business1_footer = document.querySelector(".include_localNav_business1_footer");
    if( business1_footer ) {
      fetch("/_includes/localNav_business1_footer.html")
      .then((response) => response.text())
      .then((data) => {
        let target = document.querySelector(".include_localNav_business1_footer");
        if (target) {
          target.innerHTML = data;
        }
      });
    }

  }

  hoverLocalNav() {
    const mainList = document.querySelector(".p-localNav1__list1");
    const mainListItems = document.querySelectorAll(".p-localNav1__list1__item1");
    const subLists = document.querySelectorAll(".p-localNav1__list2");

    mainListItems.forEach((el) => {
      let id = el.getAttribute("aria-controls");
      if (id) {
        let x = el.getBoundingClientRect().left;
        //document.querySelector("#" + id).style.left = x + "px";
        document.querySelector("#" + id).style.left = x * 1.5 + "px";

        window.addEventListener("resize", (e) => {
          if (window.innerWidth >= 1600) {
            let x = el.getBoundingClientRect().left;
            //document.querySelector("#" + id).style.left = x + "px";
            document.querySelector("#" + id).style.left = x * 1.5 + "px";
          }
        });

        //el.addEventListener("click", (e) => {
        //  if (!el.querySelector("a").classList.contains("-open")) {
        //    removeSubNav();
        //    document.querySelector("#" + id).classList.add("-open");
        //    el.querySelector("a").classList.add("-open");
        //  } else {
        //    removeSubNav();
        //  }
        //});
      }
    });

    function removeSubNav() {
      mainListItems.forEach((el) => {
        el.querySelector("a").classList.remove("-open");
      });
      subLists.forEach((el) => {
        el.classList.remove("-open");
      });
    }

    function scroll() {
      mainList.addEventListener("scroll", () => {
        subLists.forEach((el) => {
          el.style.transform = "translateX(-" + mainList.scrollLeft + "px)";
        });
      });
    }

    scroll();
  }

  scrollLocalNav() {
    const el = document.querySelector(".p-localNav1");
    const sticky = document.querySelector(".p-localNav1__sticky1");
    window.addEventListener("scroll", function () {
      var scrollDistance = window.scrollY || document.documentElement.scrollTop;
      const mobile = isMobileSize();

      if (scrollDistance >= 0) {
        el.classList.add("-fixed");
        el.style.transform = "translateY("+ scrollDistance * 1.5 +"px)";
        if( sticky && !mobile ) {
          sticky.classList.add("-fixed");
          sticky.style.transform = "translateY("+ scrollDistance * 1.5 +"px)";
        }
      } else {
        el.classList.remove("-fixed");
        el.style.transform = "translateY(0)";
        if( sticky && !mobile ) {
          sticky.classList.remove("-fixed");
          sticky.style.transform = "translateY(0)";
        }
      }

      if (el.classList.contains("-fixed")) {
        el.querySelector(".p-localNav1__inner1").style.left = -window.scrollX + "px";
      } else {
        el.querySelector(".p-localNav1__inner1").style.left = 0;
      }
    });
  }

  scrollLocalNavSticky() {
    const el = document.querySelector(".p-localNav1__sticky1");
    const mobile = isMobileSize();

    if( el && mobile ) {
      let pos;
      const careerTop = document.querySelector(".p-careertop-block1");

      if( careerTop ) {
        const careerTopH = careerTop.offsetHeight;
        pos = careerTopH - 55 - 46 - 72;
      } else {
        pos = /*72 + */40;
      }

      window.addEventListener("scroll", function () {
        var scrollDistance = window.scrollY || document.documentElement.scrollTop;
        console.log(pos)

        if (scrollDistance >= pos) {
          el.classList.add("-fixed");
        } else {
          el.classList.remove("-fixed");
        }
      });
    }
  }

  setCurrentClass() {
    const url = window.location.href;
    const parts = url.split("/");
    let result;

    if (parts.length >= 3) {
      parts.splice(0, 3);
      result = "/" + parts.join("/");
    } else {
      result = url;
    }

    const mainList = document.querySelectorAll(".p-localNav1__list1__item1");
    const subList = document.querySelectorAll(".p-localNav1__list2__item1>a");

    mainList.forEach((el) => {
      if (el.querySelector("a").getAttribute("href") === result) {
        el.querySelector("a").classList.add("-current");
      }
    });

    subList.forEach((el) => {
      if (el.getAttribute("href") === result) {
        const id = el.closest(".p-localNav1__list2").getAttribute("id");
        mainList.forEach((el) => {
          if (el.getAttribute("aria-controls") === id) {
            el.querySelector("a").classList.add("-current");
          }
        });
      }
    });
  }
}

export default LocalNav1;
