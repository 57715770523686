import { gsap } from "gsap";

class Block12 {
  constructor() {
    if (document.querySelector(".p-block12")) {
      this.initialize();
    }
  }
  initialize() {
    const title = document.querySelectorAll(".p-block12__title1");
    const button = document.querySelectorAll(".p-block12__button1");

    title.forEach((el) => {
      const button = el.querySelector(".c-button11");
      if (button) {
        el.querySelector(".c-button11").addEventListener("click", () => {
          if (el.querySelector(".c-button11").getAttribute("aria-expanded") === "false") {
            el.querySelector(".c-button11").setAttribute("aria-expanded", "true");
            el.querySelector(".c-button11").innerHTML = "閉じる";
            el.nextElementSibling.querySelectorAll(".c-card8__toggleButton1").forEach((el) => {
              el.classList.add("-open");
            });
            el.nextElementSibling.querySelectorAll(".c-card8__toggleArea1").forEach((el) => {
              gsap.to(el, {
                duration: 0.5,
                height: "auto",
                ease: "Power4.easeOut",
              });
            });
          } else {
            el.querySelector(".c-button11").setAttribute("aria-expanded", "false");
            el.querySelector(".c-button11").innerHTML = "開く";
            el.nextElementSibling.querySelectorAll(".c-card8__toggleButton1").forEach((el) => {
              el.classList.remove("-open");
            });
            el.nextElementSibling.querySelectorAll(".c-card8__toggleArea1").forEach((el) => {
              gsap.to(el, {
                duration: 0.5,
                height: "0",
                ease: "Power4.easeOut",
              });
            });
          }
        });
      }
    });

    button.forEach((el) => {
      if (button) {
        el.querySelector(".c-button9").addEventListener("click", () => {
          if (el.querySelector(".c-button9").getAttribute("aria-expanded") === "false") {
            el.querySelector(".c-button9").setAttribute("aria-expanded", "true");
            el.querySelector(".c-button9").innerHTML = "閉じる";
            document.querySelector(".p-block1__button1 .c-button9").setAttribute("aria-expanded", "true");
            document.querySelector(".p-block1__button1 .c-button9").innerHTML = "すべて閉じる";

            document.querySelectorAll(".p-block12__list1__item1 .c-card8__toggleButton1").forEach((el) => {
              el.classList.add("-open");
            });
            document.querySelectorAll(".p-block12__list1__item1 .c-card8__toggleArea1").forEach((el) => {
              gsap.to(el, {
                duration: 0.5,
                height: "auto",
                ease: "Power4.easeOut",
              });
            });
          } else {
            el.querySelector(".c-button9").setAttribute("aria-expanded", "false");
            el.querySelector(".c-button9").innerHTML = "開く";
            document.querySelector(".p-block1__button1 .c-button9").setAttribute("aria-expanded", "false");
            document.querySelector(".p-block1__button1 .c-button9").innerHTML = "すべて開く";
            document.querySelectorAll(".p-block12__list1__item1 .c-card8__toggleButton1").forEach((el) => {
              el.classList.remove("-open");
            });
            document.querySelectorAll(".p-block12__list1__item1 .c-card8__toggleArea1").forEach((el) => {
              gsap.to(el, {
                duration: 0.5,
                height: "0",
                ease: "Power4.easeOut",
              });
            });
          }
        });
      }
    });
  }
}
export default Block12;
