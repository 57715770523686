class Block9 {
  constructor() {
    if (document.querySelector(".p-block9")) {
      this.setEvent();
    }
  }

  setEvent() {
    const areaLv1 = document.querySelectorAll(".p-block9__areaSelect1__item1__formRadio1");
    //const areaMobileLv2 = document.querySelectorAll(".p-block9__areaSelect1__item1__formSelect1");
    //const areaDesktopLv2 = document.querySelectorAll(".p-block9__areaSelect2");

    areaLv1.forEach((el) => {
      el.querySelector("label").addEventListener("click", () => {
        areaLv1.forEach((el) => {
          el.querySelector(".c-formRadio1").classList.remove("-active");
        });
        el.querySelector(".c-formRadio1").classList.add("-active");
    //    areaMobileLv2.forEach((el) => {
    //      el.classList.remove("-active");
    //    });
    //    el.nextElementSibling.classList.add("-active");

    //    areaDesktopLv2.forEach((el) => {
    //      el.classList.remove("-active");
    //    });

    //    const id = "#" + el.querySelector("label").getAttribute("for") + "_body";
    //    document.querySelector(id).classList.add("-active");
        window.location.href = el.querySelector(".c-formRadio1 label").previousElementSibling.value;
      });
    });

    //areaMobileLv2.forEach((el) => {
    //  el.querySelector("select").addEventListener("change", () => {
    //    window.location.href = el.querySelector("select").value;
    //  });
    //});

    //areaDesktopLv2.forEach((el) => {
    //  el.querySelectorAll(".c-formRadio2 label").forEach((el) => {
    //    el.addEventListener("click", (e) => {
    //      window.location.href = el.previousElementSibling.value;
    //    });
    //  });
    //});
  }
}

export default Block9;
