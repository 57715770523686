import jump from "jump.js";

class SmoothScroll1 {
  constructor() {
    const links = document.querySelectorAll(".smoothScroll");
    let offset = 0;

    if (document.querySelector(".p-localNav1") && window.innerWidth > 768) {
      offset = -70 - 30;
    }

    links.forEach((element) => {
      element.addEventListener("click", (e) => {
        e.preventDefault();
        jump(e.currentTarget.getAttribute("href"), {
          duration: 1000,
          offset: offset,
        });
      });
    });
  }
}
export default SmoothScroll1;
