import MicroModal from "micromodal";

class Block4 {
  constructor() {
    if (document.querySelector(".p-block4")) {
      this.setEvent();
      this.loadYoutubeAPI();

      window.addEventListener("load", () => {
        this.players = [];
        this.addYoutubeMovie();
      });
    }
  }

  setEvent() {
    const buttons = document.querySelectorAll(".p-block4__list1 .c-list6 .c-list6__item1 .c-card2");

    buttons.forEach((el) => {
      el.addEventListener("click", (e) => {
        if (window.innerWidth > 768) {
          e.preventDefault();
          const id = e.currentTarget.getAttribute("data-micromodal-trigger");
          this.toggleModal(id);
        }
      });
    });
  }

  toggleModal(id) {
    MicroModal.show(id, {
      onClose: () => {
        this.stopMovie();
      },
    });
  }

  loadYoutubeAPI() {
    const tag = document.createElement("script");
    const firstScriptTag = document.getElementsByTagName("script")[0];

    tag.src = "https://www.youtube.com/player_api";
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  addYoutubeMovie() {
    const tags = document.querySelectorAll(".c-modal1__movie1");

    tags.forEach((el) => {
      this.players.push(
        new YT.Player(el, {
          videoId: el.getAttribute("id"),
        })
      );
    });
  }

  stopMovie() {
    this.players.forEach((el) => {
      el.pauseVideo();
    });
  }
}

export default Block4;
