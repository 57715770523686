import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class Header1 {
  constructor() {
    fetch("/_includes/header-default.html")
      .then((response) => response.text())
      .then((data) => {
        document.querySelector(".include_header_default").innerHTML = data;

        if (document.querySelector(".p-header1")) {
          this.mediaQueryList = window.matchMedia("(max-width: 769px)");
          this.buttonToggleMobile = document.querySelector(".p-mobileNav1__button1");
          this.buttonToggleDesktop = document.querySelector(".p-header1__desktop1__button1");
          this.menu = this.buttonToggleMobile.nextElementSibling;
          this.flg = true;
          this.nav = document.querySelector(".p-mobileNav1");
          this.focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';

          //初回アップデート
          this.initialize();
        }
      });
  }

  initialize() {
    this.mediaQueryList.addEventListener("change", this.resize.bind(this));
    this.resize();
    this.toggleAllMenu();
    this.toggleCategoryNav();
    this.loadGoogleSearch();
    this.desktopSearchForm();
    this.mobileSearchForm();
    this.mobileNavLv1();
    this.mobileNavLv2();
    this.desktopNav();
    this.desktopHeaderScroll();
    this.initLang();
    this.selectLang();
    this.setCurrentClass();
  }

  // ウィンドウサイズ変更時の処理
  resize() {
    if (this.mediaQueryList.matches) {
      // SPサイズになった時の処理
      this.resetNav();
    } else {
      // PCサイズになった時の処理
      this.resetNav();
    }
  }

  // メニューの開閉処理
  toggleAllMenu() {
    // mobile処理
    this.buttonToggleMobile.addEventListener("click", (e) => {
      if (!document.querySelector(".p-header1").classList.contains("-open")) {
        document.querySelector(".p-header1").classList.add("-open");
        this.buttonToggleMobile.setAttribute("aria-expanded", "true");
        this.buttonToggleMobile.querySelector(".p-mobileNav1__button1__label1").textContent = "Close";
        this.backgroundFix(this.flg);
        this.flg = false;
      } else {
        document.querySelector(".p-header1").classList.remove("-open");
        this.buttonToggleMobile.setAttribute("aria-expanded", "false");
        this.buttonToggleMobile.querySelector(".p-mobileNav1__button1__label1").textContent = "Menu";
        this.backgroundFix(this.flg);
        this.flg = true;
      }
    });

    // desktop処理
    this.buttonToggleDesktop.addEventListener("click", (e) => {
      if (!document.querySelector(".p-header1").classList.contains("-open")) {
        document.querySelector(".p-header1").classList.add("-open");
        document.querySelector(".p-header1").classList.add("-all");
        this.buttonToggleDesktop.setAttribute("aria-expanded", "true");
        this.buttonToggleDesktop.querySelector(".p-header1__desktop1__button1__label1").textContent = "Close";
        this.backgroundFix(this.flg);
        this.flg = false;
      } else {
        if (!document.querySelector(".p-header1").classList.contains("-all")) {
          this.removeClass();
          document.querySelector(".p-header1").classList.add("-all");
          this.buttonToggleDesktop.setAttribute("aria-expanded", "true");
          this.buttonToggleDesktop.querySelector(".p-header1__desktop1__button1__label1").textContent = "Close";
        } else {
          document.querySelector(".p-header1").classList.remove("-open");
          document.querySelector(".p-header1").classList.remove("-all");
          this.buttonToggleDesktop.setAttribute("aria-expanded", "false");
          this.buttonToggleDesktop.querySelector(".p-header1__desktop1__button1__label1").textContent = "Menu";
          this.backgroundFix(this.flg);
          this.closeAccordion();
          this.flg = true;
        }
      }
    });
  }

  toggleCategoryNav() {
    const navs = document.querySelectorAll(".p-header1__desktop1__list1__item1 a");
    navs.forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        if (!document.querySelector(".p-header1").classList.contains("-open")) {
          // メニューが出ていない状態
          if (!el.classList.contains("-active")) {
            document.querySelector(".p-header1").classList.add("-open");
            this.removeClass();
            document.querySelector(".p-header1").classList.add(el.getAttribute("href").replace("#", "-"));
            el.classList.add("-active");
            this.buttonToggleDesktop.setAttribute("aria-expanded", "true");
            this.buttonToggleDesktop.querySelector(".p-header1__desktop1__button1__label1").textContent = "Menu";
            this.backgroundFix(this.flg);
            this.flg = false;
          }
        } else {
          // メニューが出ている状態
          if (!el.classList.contains("-active")) {
            this.removeClass();
            document.querySelector(".p-header1").classList.add(el.getAttribute("href").replace("#", "-"));
            el.classList.add("-active");
            this.buttonToggleDesktop.setAttribute("aria-expanded", "true");
            this.buttonToggleDesktop.querySelector(".p-header1__desktop1__button1__label1").textContent = "Menu";
          } else {
            document.querySelector(".p-header1").classList.remove("-open");
            this.removeClass();
            this.buttonToggleDesktop.setAttribute("aria-expanded", "false");
            this.buttonToggleDesktop.querySelector(".p-header1__desktop1__button1__label1").textContent = "Menu";
            this.backgroundFix(this.flg);
            this.closeAccordion();
            this.flg = true;
          }
        }
      });
    });
  }

  removeClass() {
    const navs = document.querySelectorAll(".p-header1__desktop1__list1__item1 a");
    navs.forEach((el) => {
      el.classList.remove("-active");
    });

    const classes = Array.from(document.querySelector(".p-header1").classList);
    const keyword = ["-all", "-service", "-company", "-support"];
    const filteredClasses = classes.filter(function (className) {
      return !keyword.includes(className);
    });
    document.querySelector(".p-header1").className = filteredClasses.join(" ");
  }

  // メニューが出たらスクロールを停止させる
  // true：停止 / false：停止解除
  backgroundFix(bool) {
    // ナビゲーションを開いた時点でのスクロール位置を取得
    const scrollingElement = document.scrollingElement;

    // true：スクロール位置を取得 / false：bodyに設定されているtopの値（scrollingElement.scrollTop）を取得する。ないなら0
    const scrollY = bool ? scrollingElement.scrollTop : parseInt(document.body.style.top || "0");

    // 固定させる時につけるbodyのstyle
    const fixedStyles = {
      height: "100vh",
      position: "fixed",
      top: `${scrollY * -1}px`,
      left: "0",
      width: "100%",
    };

    // true：bodyにstyleを反映 / false：追加したbodyのスタイルを全部消す
    Object.keys(fixedStyles).forEach((key) => {
      document.body.style[key] = bool ? fixedStyles[key] : "";
    });

    // false:スクロール位置を元に戻す
    if (!bool) {
      window.scrollTo(0, scrollY * -1);
    }
  }

  // bodyのスタイルをリセット
  resetBodyStyle() {
    const fixedStyles = {
      height: "100vh",
      position: "fixed",
      top: "",
      left: "0",
      width: "100%",
    };

    Object.keys(fixedStyles).forEach((key) => {
      document.body.style[key] = "";
    });
  }

  // モバイル時のナビ第1階層（横スライド）
  mobileNavLv1() {
    const buttons = document.querySelectorAll(".p-mobileNav1__list1__item1>button");
    const all = document.querySelectorAll(".p-mobileNav1__button2");

    buttons.forEach((el) => {
      el.addEventListener("click", () => {
        const id = el.getAttribute("id");
        const innerElement = document.querySelector(".p-mobileNav1__inner1");
        innerElement.classList.add("-" + id);
      });
    });

    all.forEach((el) => {
      el.addEventListener("click", () => {
        this.resetClass();
        this.closeAccordion();
      });
    });
  }

  // モバイル時のナビ第2階層（アコーディオン）
  mobileNavLv2() {
    const buttons = document.querySelectorAll(".p-mobileNav1__navLv2__list1__item1 button");
    buttons.forEach((el) => {
      el.addEventListener("click", () => {
        if (el.getAttribute("aria-expanded") === "false") {
          el.setAttribute("aria-expanded", "true");
        } else {
          el.setAttribute("aria-expanded", "false");
        }
      });
    });
  }

  // デスクトップ時のアコーディオン
  desktopNav() {
    const buttons = document.querySelectorAll(".p-desktopNav1__list2__item1>button");
    buttons.forEach((el) => {
      el.addEventListener("click", () => {
        if (el.getAttribute("aria-expanded") === "false") {
          el.setAttribute("aria-expanded", "true");
        } else {
          el.setAttribute("aria-expanded", "false");
        }
      });
    });
  }

  // アコーディオンを全て閉じる
  closeAccordion() {
    const mobileButtons = document.querySelectorAll(".p-mobileNav1__navLv2__list1__item1 button");
    const desktopButtons = document.querySelectorAll(".p-desktopNav1__list2__item1 button");
    mobileButtons.forEach((el) => {
      el.setAttribute("aria-expanded", "false");
    });
    desktopButtons.forEach((el) => {
      el.setAttribute("aria-expanded", "false");
    });
  }

  // モバイルメニュー内で、service, company, supportの選択状態をリセットする
  resetClass() {
    const innerElement = document.querySelector(".p-mobileNav1__inner1");
    const innerElementClasses = innerElement.classList;

    innerElementClasses.forEach((className) => {
      if (className !== "p-mobileNav1__inner1") {
        innerElementClasses.remove(className);
      }
    });
  }

  // ウィンドウサイズが変わった時に全てのナビゲーションの状態を解除する
  resetNav() {
    const header = document.querySelector(".p-header1");

    header.classList.remove("-open");
    this.buttonToggleMobile.setAttribute("aria-expanded", "false");
    this.buttonToggleMobile.querySelector(".p-mobileNav1__button1__label1").textContent = "Menu";
    this.buttonToggleDesktop.setAttribute("aria-expanded", "false");
    this.buttonToggleDesktop.querySelector(".p-header1__desktop1__button1__label1").textContent = "Menu";
    this.resetClass();
    this.closeAccordion();
    this.resetBodyStyle();
    this.backgroundFix(false);
    this.flg = true;
  }

  // fixedされたヘッダーの横スクロール
  desktopHeaderScroll() {
    const element = document.querySelector(".p-header1__desktop1__inner1");
    const nav = document.querySelector(".p-desktopNav1");
    const scrollBarWidth = window.innerWidth - document.body.clientWidth;

    window.addEventListener("scroll", function () {
      let scrollDistance = window.scrollY || document.documentElement.scrollTop;

      if (scrollDistance >= 86) {
        element.classList.add("-fixed");
      } else {
        element.classList.remove("-fixed");
      }

      if (element.classList.contains("-fixed")) {
        element.style.left = -window.scrollX + "px";
      } else {
        element.style.left = 0;
      }
    });

    nav.addEventListener("scroll", () => {
      element.style.left = -nav.scrollLeft + "px";
    });
  }

  // Googleカスタム検索
  loadGoogleSearch() {
    var target;
    if (this.mediaQueryList.matches) {
      target = document.querySelector(".p-mobileNav1__search1");
    } else {
      target = document.querySelector(".p-header1__desktop1__search1");
    }
    const div = document.createElement("div");
    div.classList.add("gcse-searchbox-only");
    target.appendChild(div);
    const scr = document.createElement("script");
    scr.src = "https://cse.google.com/cse.js?cx=828f30ee1fb0f4684";
    scr.async = true;
    target.appendChild(scr);
  }

  // 検索ボックスデスクトップ
  desktopSearchForm() {
    //const form = document.querySelector(".p-header1__desktop1__search1 .c-formText1");
    const form = document.querySelector(".p-header1__desktop1__search1");

    form.addEventListener("click", (el) => {
      if (!el.target.classList.contains("-active")) {
        el.target.classList.add("-active");
      }

      const input = form.querySelector("input");
      let ph = input.placeholder;
      if (!ph) {
        input.placeholder = "Search..";
      }
    });
  }

  // 検索ボックスモバイル
  mobileSearchForm() {
    const button = document.querySelector(".p-header1__mobile1__header1__button2");
    const form = document.querySelector(".p-mobileNav1__search1");

    button.addEventListener("click", (el) => {
      if (!el.target.classList.contains("-active")) {
        el.target.classList.add("-active");
        gsap.to(form, {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          duration: 0.8,
          ease: "Power4.easeOut",
        });
      } else {
        el.target.classList.remove("-active");
        gsap.to(form, {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
          duration: 0.8,
          ease: "Power4.easeOut",
        });
      }

      const input = form.querySelector("input");
      let ph = input.placeholder;
      if (!ph) {
        input.placeholder = "Search..";
      }
    });
  }

  // 言語選択
  initLang() {
    let cookies = document.cookie.split(";");
    cookies.forEach(function (value) {
      let content = value.split("=");
      if (content[0].indexOf("stt_lang") !== -1) {
        if (content[1] == "ja") {
          setLang("japan");
        } else {
          setLang("global");
        }
      }
    });

    function setLang(lang) {
      const element = document.querySelectorAll(".c-formSelect1__selected1");

      element.forEach((el) => {
        if (lang === "japan") {
          el.classList.remove("-global");
          el.classList.add("-japan");
        } else {
          el.classList.remove("-japan");
          el.classList.add("-global");
        }
      });
    }
  }

  selectLang() {
    const selectedLabel = document.querySelectorAll(".c-formSelect1__selected1");
    selectedLabel.forEach((el) => {
      el.addEventListener("click", () => {
        toggleLangMenu();
      });
    });

    const langList = document.querySelectorAll(".c-formSelect1__list1__item1");
    langList.forEach((el) => {
      el.addEventListener("click", () => {
        if (el.classList.contains("-japan")) {
          setLang("japan");
          toggleLangMenu();
          jump("japan");
        } else {
          setLang("global");
          toggleLangMenu();
          jump("global");
        }
      });
    });

    function toggleLangMenu() {
      const selectedLabel = document.querySelectorAll(".c-formSelect1__selected1");
      const selectedLabelShutto = document.querySelectorAll(".stt-lang-select")[0];
      selectedLabel.forEach((el) => {
        if (!el.classList.contains("-open")) {
          el.classList.add("-open");
          selectedLabelShutto.classList.add("on");
        } else {
          el.classList.remove("-open");
          selectedLabelShutto.classList.add("on");
        }
      });
      //const data = document.querySelectorAll('.stt-lang-select');
      //data[0].click();
      //const selectedLabelShutto = document.querySelectorAll(".stt-lang-select");
      //selectedLabelShutto.forEach((el) => {
      //  if (!el.classList.contains("on")) {
      //    el.classList.add("on");
      //  } else {
      //    el.classList.remove("on");
      //  }
      //});
    }

    function setLang(lang) {
      const element = document.querySelectorAll(".c-formSelect1__selected1");

      element.forEach((el) => {
        if (lang === "japan") {
          el.classList.remove("-global");
          el.classList.add("-japan");
        } else {
          el.classList.remove("-japan");
          el.classList.add("-global");
        }
      });
    }

    function jump(lang) {
      //const url = window.location.href;

      if (/*url.indexOf("jp-ja") !== -1 && */ lang === "global") {
        //window.location.href = "/en";
        const data = document.querySelectorAll('[data-code="EN"]');
        //console.log(data)

        //data[0].addEventListener ( "click", function() {
        //  console.log('ENをクリック')
        //});

        data[0].click();
      }

      if (/*url.indexOf("jp-ja") === -1 && */ lang === "japan") {
        //window.location.href = "/jp-ja";
        const data = document.querySelectorAll('[data-code="JA"]');
        //console.log(data)

        //data[0].addEventListener ( "click", function() {
        //  console.log('JAをクリック')
        //});

        data[0].click();
      }
    }
  }

  setCurrentClass() {
    const mainList = document.querySelectorAll(".p-header1__desktop1__list1__item1");
    const subList = document.querySelectorAll(".p-desktopNav1__list5__item1");

    const url = window.location.href;
    const parts = url.split("/");
    let result;

    if (parts.length >= 3) {
      parts.splice(0, 3);
      result = "/" + parts.join("/");
    } else {
      result = url;
    }
    result = result.split("#")[0];

    subList.forEach((el) => {
      if (result === el.querySelector("a").getAttribute("href")) {
        const category = el.closest(".p-desktopNav1__list4").classList[1].replace("-", "#");
        mainList.forEach((el) => {
          if (el.querySelector("a").getAttribute("href") === category) {
            el.querySelector("a").classList.add("-current");
          }
        });
      }
    });
  }
}

export default Header1;
