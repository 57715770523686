import MicroModal from "micromodal";

class Button3 {
  constructor() {
    if (document.querySelector(".c-button3")) {
      this.setEvent();
    }
  }

  setEvent() {
    const buttons = document.querySelectorAll(".c-button3");

    buttons.forEach((el) => {
      el.addEventListener("click", (e) => {
        if (!el.classList.contains("-active")) {
          el.classList.add("-active");
        } else {
          el.classList.remove("-active");
        }
      });
    });
  }
}

export default Button3;
