import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class Block16 {
  constructor() {
    if (document.querySelector(".p-block16")) {
      this.tabs = document.querySelectorAll(".p-block16__tabs .c-tab1__list1__item1");
      this.tabBodys = document.querySelectorAll(".p-block16__tabBody1__unit1");
      this.allToggleButton = document.querySelectorAll(".p-block16__button1 button");
      this.cardButtons = document.querySelectorAll(".p-block16 .c-card8__toggleButton1");
      this.cardBodys = document.querySelectorAll(".p-block16 .c-card8__toggleArea1");
      this.scrollButtons = document.querySelectorAll(".p-block16 .c-tab1__button1");
      this.tabLists = document.querySelectorAll(".p-block16 .c-tab1");
      this.windowW = window.innerWidth;
      this.negativeMargin;
      if (this.windowW > 768) {
        this.negativeMargin = 40;
      } else {
        this.negativeMargin = 4;
      }
      this.scrollButton();
      this.setTabEvent();
      this.setButtonToggleEvent();
    }
  }

  scrollButton() {
    window.addEventListener("resize", () => {
      setTableClass();
      if (this.windowW > 768) {
        this.negativeMargin = 40;
      } else {
        this.negativeMargin = 4;
      }
    });

    this.tabLists.forEach((el) => {
      el.querySelector(".c-tab1__list1").addEventListener("scroll", (e) => {
        if (el.querySelector(".c-tab1__list1").scrollLeft >= 1) {
          hideScroll(el.querySelector(".c-tab1__button1"));
        } else if (el.querySelector(".c-tab1__list1").scrollLeft <= 1) {
          showScroll(el.querySelector(".c-tab1__button1"));
        }
      });
    });

    const showScroll = (el) => {
      gsap.to(el, {
        opacity: 1,
        duration: 0.5,
        x: 0,
        ease: "Power4.easeOut",
      });
    };

    const hideScroll = (el) => {
      gsap.to(el, {
        opacity: 0,
        duration: 0.5,
        x: 20,
        ease: "Power4.easeOut",
      });
    };

    const setTableClass = () => {
      this.tabLists.forEach((el) => {
        if (el.offsetWidth > el.querySelector(".c-tab1__list1 ul").offsetWidth - this.negativeMargin) {
          el.classList.remove("-scroll");
          hideScroll(el.querySelector(".c-tab1__button1"));
        } else {
          el.classList.add("-scroll");
          showScroll(el.querySelector(".c-tab1__button1"));
        }
      });
    };
    setTableClass();
  }

  setButtonToggleEvent() {
    this.allToggleButton.forEach((el) => {
      el.addEventListener("click", (e) => {
        if (el.getAttribute("aria-expanded") === "false") {
          this.accordionAllOpen();
        } else {
          this.accordionAllClose();
        }
      });
    });
  }

  accordionAllOpen() {
    this.allToggleButton.forEach((el) => {
      el.setAttribute("aria-expanded", "true");
      el.innerHTML = "すべて閉じる";
    });

    this.cardButtons.forEach((el) => {
      el.classList.add("-open");
    });

    this.cardBodys.forEach((el) => {
      gsap.to(el, {
        duration: 0.5,
        height: "auto",
        ease: "Power4.easeOut",
      });
    });
  }

  accordionAllClose() {
    this.allToggleButton.forEach((el) => {
      el.setAttribute("aria-expanded", "false");
      el.innerHTML = "すべて開く";
    });

    this.cardButtons.forEach((el) => {
      el.classList.remove("-open");
    });

    this.cardBodys.forEach((el) => {
      gsap.to(el, {
        duration: 0.5,
        height: "0",
        ease: "Power4.easeOut",
      });
    });
  }

  setTabEvent() {
    this.tabs.forEach((el) => {
      const button = el.querySelector(".c-tab1__list1__item1__button1");
      const id = button.getAttribute("data-target");

      if( id ) {
        button.addEventListener("click", (e) => {
          this.resetTabClass();
          button.classList.add("-active");
          document.querySelector(id).classList.add("-active");
        });
      }
    });
  }

  resetTabClass() {
    this.tabs.forEach((el) => {
      const button = el.querySelector(".c-tab1__list1__item1__button1");
      button.classList.remove("-active");
    });

    this.tabBodys.forEach((el) => {
      el.classList.remove("-active");
    });
  }
}
export default Block16;
