import MicroModal from "micromodal";

class Button1 {
  constructor() {
    if (document.querySelector(".c-button1")) {
      this.setEvent();
    }
  }

  setEvent() {
    const buttons = document.querySelectorAll(".c-button1");

    buttons.forEach((el) => {
      el.addEventListener("click", (e) => {
        if (!el.classList.contains("-active")) {
          el.classList.add("-active");
        } else {
          el.classList.remove("-active");
        }
      });
    });
  }
}

export default Button1;
