import { gsap } from "gsap";

class Card20 {
  constructor() {
    if (document.querySelector(".c-card20")) {
      this.setEvent();
    }
  }
  setEvent() {
    const buttons = document.querySelectorAll(".c-card20__toggleButton1");
    buttons.forEach((el) => {
      el.addEventListener("click", (e) => {
        if (!el.classList.contains("-open")) {
          el.classList.add("-open");
          el.setAttribute("aria-expanded", "true");
          gsap.to(el.nextElementSibling, {
            duration: 0.5,
            height: "auto",
            ease: "Power4.easeOut",
          });
        } else {
          el.classList.remove("-open");
          el.setAttribute("aria-expanded", "false");
          gsap.to(el.nextElementSibling, {
            duration: 0.5,
            height: "0",
            ease: "Power4.easeOut",
          });
        }
      });
    });
  }
}
export default Card20;
