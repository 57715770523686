class Newsletter1 {
  constructor() {
    if (document.querySelector(".consent-checkbox")) {
      this.consent_JS();
      this.checkAddres_JS();
    }
  }

  consent_JS() {
    const consentCheckbox = document.querySelector(".consent-checkbox");
    const inputAddres = document.querySelectorAll(".input-addres");

    consentCheckbox.addEventListener("change", (e) => {
      if (consentCheckbox.checked) {
        inputAddres.forEach((el) => {
          el.disabled = false;
        });
      } else {
        inputAddres.forEach((el) => {
          el.disabled = true;
        });
      }
    });
  }

  checkAddres_JS() {
    const sendInput = document.querySelector(".send-address-input");
    const sendSubmit = document.querySelector(".send-address-submit");
    const stopInput = document.querySelector(".stop-address-input");
    const stopSubmit = document.querySelector(".stop-address-submit");

    function validateAddress (value) {
      if( value.match( /[^A-Za-z0-9.+-/_@]+/ ) ) {
        return false;
      } else {
        return true;
      }
    }

    sendInput.addEventListener("keyup", (e) => {
      var inputText = sendInput.value,
          inputTextLength = sendInput.value.length,
          errorMesseage = document.querySelector(".error-messeage");

      if ( !validateAddress(inputText) ) {
        sendSubmit.disabled = true;
        sendInput.closest("form").querySelector(".error-messeage").style.display = "block"
      } else if ( inputTextLength === 0 ){
        sendSubmit.disabled = true;
        sendInput.closest("form").querySelector(".error-messeage").style.display = "none"
      } else {
        sendSubmit.disabled = false;
        sendInput.closest("form").querySelector(".error-messeage").style.display = "none"
      }
    });

    stopInput.addEventListener("keyup", (e) => {
      var inputText = stopInput.value,
          inputTextLength = stopInput.value.length,
          errorMesseage = document.querySelector(".error-messeage");

      if ( !validateAddress(inputText) ) {
        stopSubmit.disabled = true;
        stopInput.closest("form").querySelector(".error-messeage").style.display = "block"
      } else if ( inputTextLength === 0 ){
        stopSubmit.disabled = true;
        stopInput.closest("form").querySelector(".error-messeage").style.display = "none"
      } else {
        stopSubmit.disabled = false;
        stopInput.closest("form").querySelector(".error-messeage").style.display = "none"
      }
    });

  }

}

export default Newsletter1;
