import jump from "jump.js";

class loadPosition1 {
  constructor() {
    const pos = location.hash;
    let offset = 0;

    if (document.querySelector(".p-localNav1") && window.innerWidth > 768) {
      offset = -70 - 30;
    }

    if (pos) {
      jump(pos, {
        duration: 1000,
        offset: offset,
      });
    }
  }
}
export default loadPosition1;
