import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class Table5 {
  constructor() {
    if (document.querySelector(".c-table5")) {
      this.windowW = window.innerWidth;
      this.negativeMargin;
      if (this.windowW > 768) {
        this.negativeMargin = 40;
      } else {
        this.negativeMargin = 4;
      }
      this.initialize();
    }
  }

  initialize() {
    const tables = document.querySelectorAll(".c-table5");

    window.addEventListener("resize", () => {
      setTableClass();
    });

    tables.forEach((el) => {
      el.querySelector(".c-table5__inner1").addEventListener("scroll", (e) => {
        if (el.querySelector(".c-table5__inner1").scrollLeft >= 1) {
          hideScroll(el.querySelector(".c-table5__button1"));
        } else if (el.querySelector(".c-table5__inner1").scrollLeft <= 1) {
          showScroll(el.querySelector(".c-table5__button1"));
        }
      });
    });

    const showScroll = (el) => {
      gsap.to(el, {
        opacity: 1,
        duration: 0.5,
        x: 0,
        ease: "Power4.easeOut",
      });
    };

    const hideScroll = (el) => {
      gsap.to(el, {
        opacity: 0,
        duration: 0.5,
        x: 20,
        ease: "Power4.easeOut",
      });
    };

    let tableMinW = [];
    const setTableClass = () => {
      tables.forEach((el, i) => {
        if (!tableMinW[i] && el.offsetWidth < el.querySelector(".c-table5__inner1 table").offsetWidth) {
          tableMinW[i] = el.querySelector(".c-table5__inner1 table").offsetWidth;
        }

        if (el.offsetWidth < tableMinW[i]) {
          el.classList.add("-scroll");
          showScroll(el.querySelector(".c-table5__button1"));
        }

        if (el.offsetWidth === tableMinW[i]) {
          hideScroll(el.querySelector(".c-table5__button1"));
        }

        if (el.offsetWidth >= tableMinW[i] + 12) {
          el.classList.remove("-scroll");
          hideScroll(el.querySelector(".c-table5__button1"));
        }
      });
    };
    setTableClass();
  }
}
export default Table5;
