import { gsap } from "gsap";

class Card8 {
  constructor() {
    if (document.querySelector(".c-card8")) {
      this.setEvent();
      this.jumpSection();
    }
  }

  setEvent() {
    const buttons = document.querySelectorAll(".c-card8__toggleButton1");
    buttons.forEach((el) => {
      el.addEventListener("click", (e) => {
        if (!el.classList.contains("-open")) {
          el.classList.add("-open");
          gsap.to(el.nextElementSibling, {
            duration: 0.5,
            height: "auto",
            ease: "Power4.easeOut",
          });
        } else {
          el.classList.remove("-open");
          gsap.to(el.nextElementSibling, {
            duration: 0.5,
            height: "0",
            ease: "Power4.easeOut",
          });
        }
      });
    });
  }

  jumpSection() {
    const hash = location.hash;
    if (hash) {
      const target = document.querySelector(hash);

      if (target !== null) {
        target.closest(".c-card8__toggleArea1").previousElementSibling.classList.add("-open");
        gsap.to(target.closest(".c-card8__toggleArea1"), {
          duration: 0.5,
          height: "auto",
          ease: "Power4.easeOut",
        });
        const rect = target.getBoundingClientRect().top;
        const offset = window.scrollY;
        const gap = 100;
        const targetY = rect + offset - gap;
        window.scrollTo({
          top: targetY,
        });
      }
    }
  }
}
export default Card8;
