import jump from "jump.js";

class SmoothScroll1 {
  constructor() {
    const links = document.querySelectorAll(".smoothScroll");
    let offset = 0;

    links.forEach((element) => {
      element.addEventListener("click", (e) => {
        e.preventDefault();
        if (window.innerWidth > 768) {
          if (document.querySelector(".p-localNav1")) {
            offset = -120 - 68;
          } else {
            offset = -120;
          }
        } else {
          offset = -72;
        }
        jump(e.currentTarget.getAttribute("href"), {
          duration: 1000,
          offset: offset,
        });
      });
    });
  }
}
export default SmoothScroll1;
