import { gsap } from "gsap";

class Block1 {
  constructor() {
    if (document.querySelector(".p-block1")) {
      this.initialize();
    }
  }
  initialize() {
    const buttons = document.querySelectorAll(".p-block1__button1");

    buttons.forEach((el) => {
      el.addEventListener("click", () => {
        if (el.querySelector(".c-button9").getAttribute("aria-expanded") === "false") {
          buttons.forEach((el) => {
            el.querySelector(".c-button9").setAttribute("aria-expanded", "true");
            el.querySelector(".c-button9").innerHTML = "すべて閉じる";
          });

          document.querySelectorAll(".p-block12__title1__button1").forEach((el) => {
            el.querySelector(".c-button11").setAttribute("aria-expanded", "true");
            el.querySelector(".c-button11").innerHTML = "閉じる";
          });

          document.querySelectorAll(".p-block12__button1").forEach((el) => {
            el.querySelector(".c-button9").setAttribute("aria-expanded", "true");
            el.querySelector(".c-button9").innerHTML = "閉じる";
          });

          document.querySelectorAll(".c-card8__toggleButton1").forEach((el) => {
            el.classList.add("-open");
          });

          document.querySelectorAll(".c-card8__toggleArea1").forEach((el) => {
            gsap.to(el, {
              duration: 0.5,
              height: "auto",
              ease: "Power4.easeOut",
            });
          });

          document.querySelectorAll(".c-card20__toggleButton1").forEach((el) => {
            el.classList.add("-open");
          });

          document.querySelectorAll(".c-card20__toggleArea1").forEach((el) => {
            gsap.to(el, {
              duration: 0.5,
              height: "auto",
              ease: "Power4.easeOut",
            });
          });
        } else {
          buttons.forEach((el) => {
            el.querySelector(".c-button9").setAttribute("aria-expanded", "false");
            el.querySelector(".c-button9").innerHTML = "すべて開く";
          });

          document.querySelectorAll(".p-block12__title1__button1").forEach((el) => {
            el.querySelector(".c-button11").setAttribute("aria-expanded", "false");
            el.querySelector(".c-button11").innerHTML = "開く";
          });

          document.querySelectorAll(".p-block12__button1").forEach((el) => {
            el.querySelector(".c-button9").setAttribute("aria-expanded", "false");
            el.querySelector(".c-button9").innerHTML = "開く";
          });

          document.querySelectorAll(".c-card8__toggleButton1").forEach((el) => {
            el.classList.remove("-open");
          });

          document.querySelectorAll(".c-card8__toggleArea1").forEach((el) => {
            gsap.to(el, {
              duration: 0.5,
              height: "0",
              ease: "Power4.easeOut",
            });
          });

          document.querySelectorAll(".c-card20__toggleButton1").forEach((el) => {
            el.classList.remove("-open");
          });

          document.querySelectorAll(".c-card20__toggleArea1").forEach((el) => {
            gsap.to(el, {
              duration: 0.5,
              height: "0",
              ease: "Power4.easeOut",
            });
          });
        }
      });
    });
  }
}
export default Block1;
