import { gsap } from "gsap";

class Card10 {
  constructor() {
    if (document.querySelector(".c-card10")) {
      this.initialize();
    }
  }
  initialize() {
    const button = document.querySelector(".c-card10__title1");

    button.addEventListener("click", (e) => {
      if (!button.classList.contains("-open")) {
        button.classList.add("-open");
        gsap.to(button.nextElementSibling, {
          duration: 0.5,
          height: "auto",
          ease: "Power4.easeOut",
        });
      } else {
        button.classList.remove("-open");
        gsap.to(button.nextElementSibling, {
          duration: 0.5,
          height: "0",
          ease: "Power4.easeOut",
        });
      }
    });
  }
}
export default Card10;
