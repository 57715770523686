import MicroModal from "micromodal";

class Block2 {
  constructor() {
    if (document.querySelector(".p-block2")) {
      this.setEvent();
    }
  }

  setEvent() {
    const units = document.querySelectorAll(".p-block2__unit1");

    units.forEach((el) => {
      const button = el.querySelector(".c-button7");

      button.addEventListener("click", (e) => {
        if (!button.classList.contains("-active")) {
          button.classList.add("-active");
          button.innerHTML = "閉じる";
          el.querySelector(".p-block2__unit1__list1:last-child").classList.add("-active");
        } else {
          button.classList.remove("-active");
          button.innerHTML = "開く";
          el.querySelector(".p-block2__unit1__list1:last-child").classList.remove("-active");
        }
      });
    });
  }
}

export default Block2;
