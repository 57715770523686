import SmoothScroll1 from "./modules/smoothScroll";
import loadPosition1 from "./modules/loadPosition";
import Header1 from "./modules/header1";
import Footer1 from "./modules/footer1";
import LocalNav1 from "./modules/localNav1";
import Button1 from "./modules/button1";
import Button3 from "./modules/button3";
import Button4 from "./modules/button4";
import Swiper from "./modules/swiper";
import Block1 from "./modules/block1";
import Block2 from "./modules/block2";
import Block4 from "./modules/block4";
import Block9 from "./modules/block9";
import Block12 from "./modules/block12";
import Block16 from "./modules/block16";
import Block31 from "./modules/block31";
import Card8 from "./modules/card8";
import Card10 from "./modules/card10";
import Card20 from "./modules/card20";
import Table5 from "./modules/table5";
import Table6 from "./modules/table6";
import Newsletter1 from "./modules/newsletter1";
import Rssfeed1 from "./modules/rssfeed1";

const init = () => {
  new Header1();
  new LocalNav1();
  new Footer1();
  new Button1();
  new Button3();
  new Button4();
  new Swiper();
  new Block1();
  new Block2();
  new Block4();
  new Block9();
  new Block12();
  new Block16();
  new Block31();
  new Card8();
  new Card10();
  new Card20();
  new Newsletter1();
  new Rssfeed1();
};

window.addEventListener("DOMContentLoaded", () => {
  init();
});

window.addEventListener("load", () => {
  new Table5();
  new Table6();
  new SmoothScroll1();
  new loadPosition1();
});
