class Rssfeed1 {
  constructor() {
    if (document.querySelector(".p-block3__list1__rssfeed")) {
      this.rssFeed1();
    }
  }

  rssFeed1() {
    const targets = document.querySelectorAll(".p-block3__list1__rssfeed");
    targets.forEach((el) => {
      onload = async () => {

        const parser = new DOMParser();
        const tag = el.dataset.feed;
        let feedUrl;
        if( tag ) {
          feedUrl = 'https://news.kurita-water.com/tag/' + tag + '/rss.xml';
        } else {
          feedUrl = 'https://news.kurita-water.com/rss.xml';
        }
        const response = await fetch(feedUrl);
        const data = await response.text();
        const text = await parser.parseFromString(data, "text/xml");

        const item = text.querySelectorAll("item");
        const arr = Array.from(item);
        const limit = 5;

        for (let i = 0; i < limit; i++) {
          const date = new Date(item[i].querySelector("pubDate").textContent);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const pubDate = year + "年" + month + "月" + day + "日";

          const title = item[i].querySelector("title").textContent;

          const category = item[i].querySelectorAll("category");
          const arrCategory = Array.from(category);
          let cats = "";
          for (let j = 0; j < arrCategory.length; j++) {
            const cat = category[j].textContent.replace("#", "")
            cats += '<li class="c-list5__item1"><a href="https://news.kurita-water.com/tag/' + cat + '" class="c-button4">#' + cat + '</a></li>';
          }

          const link = item[i].querySelector("link").textContent;

          const html = '<li class="c-list4__item1">'
                      +'  <p class="c-list4__item1__date1">' + pubDate + '</p>'
                      +'  <p class="c-list4__item1__title1">' + title + '</p>'
                      +'  <div class="c-list4__item1__list1">'
                      +'    <ul class="c-list5">'
                      + cats
                      +'    </ul>'
                      +'  </div>'
                      +'  <a href="' + link + '" class="c-list4__item1__link1"></a>'
                      +'</li>'

          el.innerHTML += html;

        }
      }

    });

  }

}

export default Rssfeed1;
