class Footer1 {
  constructor() {
    fetch("/_includes/footerNav.html")
      .then((response) => response.text())
      .then((data) => {
        document.querySelector(".include_nav1").innerHTML = data;

        if (document.querySelector(".p-footer1")) {
          this.accordion();
          this.pageTopButton();
        }
      });

    fetch("/_includes/footerCopy.html")
      .then((response) => response.text())
      .then((data) => {
        document.querySelector(".include_copy1").innerHTML = data;
      });
  }

  accordion() {
    const buttons = document.querySelectorAll(".p-footer1__nav1__list2__item1>button");
    buttons.forEach((el) => {
      el.addEventListener("click", () => {
        if (el.getAttribute("aria-expanded") === "false") {
          el.setAttribute("aria-expanded", "true");
        } else {
          el.setAttribute("aria-expanded", "false");
        }
      });
    });
  }

  pageTopButton() {
    const element = document.querySelector(".p-footer1__button1");
    const button = document.querySelector(".p-footer1__button1 a");

    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        element.classList.add("-active");
      } else if (window.scrollY < 10) {
        element.classList.remove("-active");
      }

      let scrollNum = window.scrollY;
      button.style.transform = "translateY("+ scrollNum * 1.5 +"px)";

    });
  }
}

export default Footer1;
