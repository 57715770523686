class Block31 {
  constructor() {
    if (document.querySelector(".p-block31")) {
      this.mediaQueryList = window.matchMedia("(max-width: 769px)");
      this.setPosition();
      this.cookie();
    }
  }

  setPosition() {
    const el_block = document.querySelector(".p-block31");
    window.addEventListener("scroll", () => {
      if (window.innerWidth > 767) {
        const scroll = window.scrollY;
        el_block.style.transform = `translateY(${scroll * (1 / 0.667)}px)`;
      } else {
        el_block.style.transform = `translateY(0)`;
      }
    });
  }

  cookie() {
    const el_btn = document.querySelector(".p-block31__button1 button");

    const consentToCookies = () => {
      function setCookie(name, value, days) {
        let expires = "";
        if (days) {
          let date = new Date();
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
          expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
      }

      setCookie("cookie_consent", "true", 365); // 1年間の有効期限を設定
    };

    // 指定した名前のクッキーを取得する関数
    function getCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    // ページ読み込み時にクッキー同意の状態を確認し、必要に応じて処理を実行
    let consentCookie = getCookie("cookie_consent");
    if (consentCookie === "true") {
      el_btn.classList.add("-agree");
    }

    el_btn.addEventListener("click", () => {
      consentToCookies();
      el_btn.classList.add("-agree");
    });
  }
}
export default Block31;
